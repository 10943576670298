import React from "react"
import { graphql } from "gatsby"

// import app components
import { FlexibleContent } from "components"

export default function DefaultPageTemplate(props) {
  const {
    data: {
      page: {
        title,
        uri,
        acf: { modules },
      },
    },
  } = props

  return (
    <>
      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            propsPage: props,

            title,
            uri,
          }}
        />
      )}
    </>
  )
}

export const CollectionQuery = graphql`
  query DefaultTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acf {
        modules {
          __typename
          ... on WpPage_Acf_Modules_Banner {
            button {
              target
              title
              url
            }
            headline
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Contact {
            formId
            text
          }
          ... on WpPage_Acf_Modules_Hero {
            hasForm
            formId
            button {
              target
              title
              url
            }
            headline
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            text
            text2
          }
          ... on WpPage_Acf_Modules_Services {
            boxes {
              headline
              items {
                text
              }
              width
            }
            headline
            introduction
          }
          ... on WpPage_Acf_Modules_Testimonials {
            headline
            items {
              text
            }
          }
          ... on WpPage_Acf_Modules_Text {
            text
          }
          ... on WpPage_Acf_Modules_Acuity {
            text
          }
          ... on WpPage_Acf_Modules_Downloads {
            fieldGroupName
            headline
            introduction
            width
            downloads {
              fieldGroupName
              logo {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 300, quality: 95) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              file {
                title
                description
                link
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Badges {
            items {
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 200, quality: 95) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_TestimonialBanner {
            text
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_LandingServices {
            badgeImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2560, quality: 95) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            text
            services {
              headline
              text
            }
            button {
              title
              target
              url
            }
          }
        }
      }
    }
  }
`
